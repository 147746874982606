import { getAllPossibleVacancyLocales } from '../constants/getAllPossibleVacancyLocales';
import { getCommonVacancyLocales } from '../constants/getCommonVacancyLocales';

export const getTranslatedVacancyLocaleOptions = (
  language: string,
): {
  value: string;
  name: string;
  common: boolean;
}[] => {
  let languagesInUserLanguage: any = null;

  if (window.Intl && 'DisplayNames' in window.Intl) {
    languagesInUserLanguage = new Intl.DisplayNames([language], {
      type: 'language',
      fallback: 'code',
    });
  }

  const options = getAllPossibleVacancyLocales().map((locale) => {
    let name: string;
    if (languagesInUserLanguage) {
      name = languagesInUserLanguage.of(locale);
    } else {
      // Use the locale string itself as a fallback name
      name = locale;
    }

    return {
      value: locale,
      name: name,
      common: getCommonVacancyLocales().includes(locale),
    };
  });

  // sort options by name using localeCompare
  options.sort((a, b) => {
    return a.name.localeCompare(b.name);
  });

  // sort commons on top but keep alphabetical order
  options.sort((a, b) => {
    if (a.common && !b.common) {
      return -1;
    } else if (!a.common && b.common) {
      return 1;
    } else {
      return 0;
    }
  });

  return options;
};

import { Pipe, PipeTransform } from '@angular/core';
import { formatDistanceStrict } from 'date-fns';
import { de } from 'date-fns/locale';

@Pipe({
  name: 'relativeTime',
  standalone: true,
})
export class RelativeTimePipe implements PipeTransform {
  transform(from: Date | string): unknown {
    if (typeof from === 'string') from = new Date(from);
    return formatDistanceStrict(from, new Date(), {
      addSuffix: true,
      locale: de,
    });
  }
}

import { Injectable } from '@angular/core';
import { catchError, map, scan, shareReplay } from 'rxjs/operators';
import {
  ChannelExportUniversaljobSearchOptionEnum,
  ChannelExportUniversaljobSearchPlanEnum,
  ChannelFragment,
  ChannelSortEnum,
  ChannelsSubscribeGQL,
  SortDirEnum,
  UniBaseXVacancyChannelAcceptRequestInput,
  UniBaseXVacancyChannelCompleteInput,
  UniBaseXVacancyChannelDraftInput,
  UniBaseXVacancyChannelFragment,
  UniBaseXVacancyChannelLiveActivateInput,
  VacancyChannelAcceptRequestGQL,
  VacancyChannelCompleteGQL,
  VacancyChannelDraftGQL,
  VacancyChannelLiveActivateGQL,
  VacancyChannelRequestGQL,
  VacancyDetailFragment,
  VacancyListItemFragment,
} from '../../../graphql/generated';
import { environment } from '../../../../environments/environment';
import { format, formatDistanceStrict } from 'date-fns';
import { de } from 'date-fns/locale';
import { DATE_FORMAT } from '../../../app.constants';
import { applySubscriptionChangesToItems } from '../../../shared/helpers/functions/applySubscriptionChangesToItems';
import { GraphQLService } from '../../../core/services/graphql.service';
import { unijobIcon } from '@intemp/unijob-ui2/particle/icon/unijob-icon.model';
import {
  allSearchPlanFeaturePoints,
  SearchPlanFeaturePointEnum,
} from './searchPlanFeaturePoint.enum';

@Injectable({
  providedIn: 'root',
})
export class ChannelsService {
  constructor(
    private vacancyChannelLiveActivateGQL: VacancyChannelLiveActivateGQL,
    private vacancyChannelCompleteGQL: VacancyChannelCompleteGQL,
    private vacancyChannelDraftGQL: VacancyChannelDraftGQL,
    private vacancyChannelRequestGQL: VacancyChannelRequestGQL,
    private vacancyChannelAcceptRequestGQL: VacancyChannelAcceptRequestGQL,
    private channelsSubscribeGQL: ChannelsSubscribeGQL,
    private graphQLService: GraphQLService,
  ) {}

  channelsSubscription$ = this.channelsSubscribeGQL
    .subscribe(
      {
        skip: 0,
        limit: 100,
        sort: {
          sortBy: ChannelSortEnum.NAME,
          sortDir: SortDirEnum.ASC,
        },
        filter: {
          criteria: [],
        },
      },
      {
        fetchPolicy: 'no-cache',
      },
    )
    .pipe(
      map((res) => ({
        items: res.data?.channelsSubscribe?.items || [],
        itemIds: res.data?.channelsSubscribe?.itemIds || [],
        changedItems: res.data?.channelsSubscribe?.changedItems || [],
        removedItems: res.data?.channelsSubscribe?.removedItems || [],
        addedItems: res.data?.channelsSubscribe?.addedItems || [],
        totalCount: res.data?.channelsSubscribe?.totalCount || null,
      })),
      scan((acc, result) => {
        return applySubscriptionChangesToItems(acc, result, '_id');
      }),
      catchError((err) => {
        this.graphQLService.handleError(err);
        throw err;
      }),
      // replay the last value to new subscribers
      shareReplay(1),
    );

  // some channels can be preconfigured, for vacancies that are not published yet
  draftChannel(input: UniBaseXVacancyChannelDraftInput) {
    return this.vacancyChannelDraftGQL
      .mutate({
        input,
      })
      .pipe(map((res) => res.data));
  }

  requestChannel(input: UniBaseXVacancyChannelDraftInput) {
    return this.vacancyChannelRequestGQL
      .mutate({
        input,
      })
      .pipe(map((res) => res.data));
  }

  acceptRequestChannel(input: UniBaseXVacancyChannelAcceptRequestInput) {
    return this.vacancyChannelAcceptRequestGQL
      .mutate({
        input,
      })
      .pipe(map((res) => res.data));
  }

  // some channels can be "completed", even when the vacancy is live
  completeChannel(input: UniBaseXVacancyChannelCompleteInput) {
    return this.vacancyChannelCompleteGQL
      .mutate({
        input,
      })
      .pipe(map((res) => res.data));
  }

  // some channels can be activated, if the vacancy is already published
  liveActivate(
    searchPlanActivationParameters: UniBaseXVacancyChannelLiveActivateInput,
  ) {
    return this.vacancyChannelLiveActivateGQL
      .mutate(
        { input: searchPlanActivationParameters },
        { fetchPolicy: 'no-cache' },
      )
      .pipe(map((res) => res.data));
  }

  public static getScreenUrl(
    vacancyNumber?: string,
    channelUuid?: string,
  ): string {
    return `${environment.universaljobScreenHost}?channelUuid=${channelUuid}&machineId=hub&vacancyUuid=${
      vacancyNumber || '?'
    }`;
  }

  public static daysUntilDateFormatted(until: Date, initial = {}) {
    return Object.assign(
      {
        until: format(until, DATE_FORMAT),
        untilDays: formatDistanceStrict(until, new Date(), {
          addSuffix: true,
          locale: de,
        }),
      },
      initial,
    );
  }

  public static daysFromDateFormatted(from: Date, initial = {}) {
    return Object.assign(
      {
        from: format(from, DATE_FORMAT),
        fromDays: formatDistanceStrict(from, new Date(), {
          addSuffix: true,
          locale: de,
        }),
      },
      initial,
    );
  }

  public static getChannelType(
    channel: ChannelFragment,
  ):
    | keyof NonNullable<UniBaseXVacancyChannelFragment['exportSettings']>
    | undefined {
    if (channel.exportSettings.universaljobSearch?.enabled) {
      return 'universaljobSearch';
    }
    if (channel.exportSettings.universaljob?.enabled) {
      return 'universaljob';
    }
    if (channel.exportSettings.universaljobScreen?.enabled) {
      return 'universaljobScreen';
    }
    if (channel.exportSettings.universaljobInternal?.enabled) {
      return 'universaljobInternal';
    }
  }

  public static getMostRecentConfig(
    configs: readonly UniBaseXVacancyChannelFragment[],
  ) {
    // the last item is the most recent configuration
    const lastIndex = configs.length - 1;
    if (lastIndex < 0) {
      return undefined;
    }
    return configs?.[lastIndex] ?? undefined;
  }

  public static isChannelAvailableForThisVacancy(
    channel: ChannelFragment,
    vacancy: VacancyDetailFragment | VacancyListItemFragment,
  ) {
    // some channels are only available for specific branches
    return (
      !channel.onlyForVacancyBranchUuids ||
      channel.onlyForVacancyBranchUuids.length === 0 ||
      channel.onlyForVacancyBranchUuids.includes(
        vacancy.uniBaseX?.customerBranch?.uuid || '',
      )
    );
  }

  public static getConfigStart(
    config?:
      | UniBaseXVacancyChannelFragment
      | NonNullable<
          VacancyListItemFragment['derivedFields']['currentChannelConfigurations']
        >[number],
  ) {
    const startDateStr = config?.runtimes?.[0]?.start;
    const startDate = startDateStr ? new Date(startDateStr) : new Date();

    // searchChannels can have a future start date defined if PENDING or REQUESTED
    if (
      (config?.exportSettings?.universaljobSearch?.enabled &&
        config?.status === 'PENDING') ||
      config?.status === 'REQUESTED'
    ) {
      return config.exportSettings?.universaljobSearch?.start
        ? new Date(config.exportSettings?.universaljobSearch?.start)
        : new Date();
    }
    return startDate;
  }

  public static getConfigEnd(
    config?:
      | UniBaseXVacancyChannelFragment
      | NonNullable<
          VacancyListItemFragment['derivedFields']['currentChannelConfigurations']
        >[number],
  ) {
    const endDateStr = config?.runtimes?.[0]?.end;
    const endDate = endDateStr ? new Date(endDateStr) : new Date();
    return endDate;
  }
  public static getConfigExpiration(
    config?:
      | UniBaseXVacancyChannelFragment
      | NonNullable<
          VacancyListItemFragment['derivedFields']['currentChannelConfigurations']
        >[number],
  ) {
    const expirationDateStr = config?.runtimes?.[0]?.expiration;
    const expirationDate = expirationDateStr
      ? new Date(expirationDateStr)
      : new Date();
    return expirationDate;
  }

  public searchPlanInfoMap: {
    [key in ChannelExportUniversaljobSearchPlanEnum]: {
      label: string;
      extensionLabel: string;
      icon: unijobIcon;
      includesFeaturePoints: SearchPlanFeaturePointEnum[];
    };
  } = {
    [ChannelExportUniversaljobSearchPlanEnum.PUSH]: {
      label: 'Push',
      extensionLabel: 'Push Extend',
      icon: 'user_group',
      includesFeaturePoints: [
        SearchPlanFeaturePointEnum.TalentExclusivity,
        SearchPlanFeaturePointEnum.ActiveSearchers,
        SearchPlanFeaturePointEnum.RecruitingViaJobPlatforms,
      ],
    },
    [ChannelExportUniversaljobSearchPlanEnum.TARGET]: {
      label: 'Target',
      extensionLabel: 'Targed Extend',
      icon: 'target_goal',
      includesFeaturePoints: allSearchPlanFeaturePoints.filter(
        (point) =>
          point !== SearchPlanFeaturePointEnum.RecruitingViaJobPlatforms,
      ),
    },
    [ChannelExportUniversaljobSearchPlanEnum.MAX]: {
      label: 'Max',
      extensionLabel: 'Max Extend',
      icon: 'rocket',
      includesFeaturePoints: allSearchPlanFeaturePoints,
    },
  };

  public getFromPlanInfoMap<
    K extends
      keyof (typeof this.searchPlanInfoMap)[ChannelExportUniversaljobSearchPlanEnum],
  >(key: K, optionType: ChannelExportUniversaljobSearchPlanEnum) {
    return this.searchPlanInfoMap?.[optionType][key];
  }

  public searchOptionInfoMap: {
    [key in ChannelExportUniversaljobSearchOptionEnum]: {
      label: string;
      icon: unijobIcon;
      warningIfActive?: string;
      featurePoints?: string[];
    };
  } = {
    [ChannelExportUniversaljobSearchOptionEnum.VIDEO]: {
      label: 'Video',
      icon: 'video_camera',
      warningIfActive: 'searchVideoActiveWarning',
      featurePoints: [
        'coversTheBroadestSearchWithMax',
        'strengthenEmployerAttractiveness',
        'completeVideoProductionWithProfessionals',
        'authenticAndProfessional',
        'modernAppearanceAndMoreVisibility',
      ],
    },
  };

  public getFromOptionInfoMap<
    K extends
      keyof (typeof this.searchOptionInfoMap)[ChannelExportUniversaljobSearchOptionEnum],
  >(key: K, optionType: ChannelExportUniversaljobSearchOptionEnum) {
    return this.searchOptionInfoMap?.[optionType][key];
  }
}

export enum SearchPlanFeaturePointEnum {
  TalentExclusivity = 'TalentExclusivity',
  ActiveSearchers = 'ActiveSearchers',
  PassiveSearchers = 'PassiveSearchers',
  RecruitingViaJobPlatforms = 'RecruitingViaJobPlatforms',
  RecruitingViaSocialMedia = 'RecruitingViaSocialMedia',
}

// Define an array to ensure order
export const allSearchPlanFeaturePoints = [
  SearchPlanFeaturePointEnum.TalentExclusivity,
  SearchPlanFeaturePointEnum.ActiveSearchers,
  SearchPlanFeaturePointEnum.PassiveSearchers,
  SearchPlanFeaturePointEnum.RecruitingViaJobPlatforms,
  SearchPlanFeaturePointEnum.RecruitingViaSocialMedia,
];
